import {
    Divider,
    List,
    ListItem,
    ListItemButton,
    Stack,
    Typography,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import { sectionBuilder, GetReportData, GetReportDataExport } from "./AARTemplateBuilder.service";
import { useCallback, useEffect, useState } from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import { ReportPage, ReportSectionOuter } from "./AARTemplateBuilder.model";
import { userHasPermission } from "../../services/User.service";
import Box from "@mui/material/Box";
import { PrintWrapper } from "./Components/Layout/PrintWrapper.component";
import ReportLanguageDropDown from "../../components/formControls/ReportLanguageDropDown.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import { Models } from "../../constants/Models.const";
import moment from "moment";

function AARTemplateBuilder() {
    const { appUser } = useApiAuthorization();
    const [isLoading, setIsLoading] = useState(false);
    const { modelID, uniqueID } = useParams();
    const [reportDetails, setReportDetails] = useState<any>([]);
    const [reportLanguage, setReportLanguage] = useState<NumericDropDownModel>();
    const [languageIdParam, setlanguageIdParam] = useState<number | null>(null);
    const {hash} = useLocation();

    const scrollTo = (elementId?: any):void => {
        setTimeout(() => {
            window.scrollTo({
                top: document.getElementById(elementId)?.offsetTop! - 65,
                behavior: "smooth",
            });
        }, 1);
    };
    const downloadExport = useCallback(() => {
        setIsLoading(true);
        GetReportDataExport({modelID, uniqueID, languageID: reportLanguage?.value}, appUser?.token).then(response => {
            if(!response.ok){
                throw Error(response.statusText);
            }
            return response.blob();
        }).then(blob => {
            if(blob != null)
            {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                let modelName = Models[modelID!];
                a.download = `${modelName}-${uniqueID}-${moment().format("MMDDYYYY")}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        }).finally(() => setIsLoading(false));
    }, [appUser?.token, modelID, uniqueID, reportLanguage]);

    useEffect(() => {
        if (
            appUser?.token &&
            userHasPermission("AssessmentReports_Detail_View", appUser)
        ) {
            setIsLoading(true);
            GetReportData(
                { uniqueID: uniqueID, modelID: modelID, languageID: languageIdParam },
                appUser.token
            )
                .then((response) => response.json())
                .then((r) => {
                    setReportDetails(r.data);
                    setReportLanguage(r.reportLanguage ?? null);
                })
                .finally(() => {
                    scrollTo(hash.replace('#', ''));
                    setIsLoading(false);
                });
        }
    }, [appUser?.token, modelID, uniqueID, languageIdParam, appUser]);

    const childMenuContent = (
        <RightMenu>
            <List component="nav">
                <ListItem>
                    <Typography variant="h6">Action Items</Typography>
                </ListItem>
                <ListItem>
                    <ReportLanguageDropDown
                        value={reportLanguage ?? null}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => { setReportLanguage(newValue ?? undefined); setlanguageIdParam(newValue != null ? newValue.value : null); }}
                        modelID={modelID!}
                    />
                </ListItem>
                <ListItemButton onClick={() => window.print()} disabled={isLoading}>
                    Print Report
                </ListItemButton>
                {userHasPermission("AssessmentReports_Detail_Export", appUser) && 
                <ListItemButton onClick={downloadExport} disabled={isLoading}>
                    Export to Excel
                </ListItemButton>
                }
                <Divider />
                <ListItem>
                    <Typography variant="h6">Navigation</Typography>
                </ListItem>
                {reportDetails.map((page: any, index: number) => {
                    return (
                        <ListItemButton
                            key={index}
                            component={Link}
                            to={`#page-` + index}
                            onClick={() => scrollTo(`page-` + index)}
                        >
                            {page.pageTitle}
                        </ListItemButton>
                    );
                })}
            </List>
        </RightMenu>
    );

    return (
        <PrintWrapper>
            <BaseLayout childMenu={childMenuContent}>
                {isLoading && <Loading />}
                <Stack>
                    <Box className="content">
                        {reportDetails.map(
                            (page: ReportPage, index: number) => {
                                return (
                                            
                                    <Box key={index}>
                                        <Box
                                            id={`page-` + index}
                                            className={"testPage"}
                                            sx={{
                                                "@media print": {
                                                    fontSize: "14px",
                                                    paddingLeft: "20px",
                                                    paddingRight: "20px"
                                                },
                                                width: '100%'
                                            }}
                                        >
                                            {page.sections.map(
                                                (
                                                    section: ReportSectionOuter,
                                                    index: number
                                                ) =>
                                                    sectionBuilder(
                                                        section,
                                                        index
                                                    )
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "block",
                                                width: "100%",
                                                borderBottom:
                                                    "1px solid rgb(0 0 0 / 12%)",
                                                margin: "50px 0px",
                                                "@media print": {
                                                    display: "none",
                                                },
                                            }}
                                        ></Box>
                                    </Box>
                                );
                            }
                        )}
                        <Typography sx={{ fontSize: "12px", color: "#fff", "@media print": { display: "none" } }}>{`${modelID}.21.7416815377`}</Typography>
                    </Box>
                </Stack>
            </BaseLayout>
        </PrintWrapper>
    );
}

export default AARTemplateBuilder;
